import React from "react";

import { FooterProps } from "./types";

const MenuItem: React.FC<React.PropsWithChildren<FooterProps>> = () => {
  return (
    <section className="grid place-items-center mx-auto footer-div">
      <div className="max-md:mx-0" style={{ width: "100%" }}>
        <div className="flex items-start max-md:block w-[87vw]  max-md:w-full  max-md:my-5 justify-between mx-20  max-md:mx-2   px-10  max-md:px-10 font-mono rounded-2xl  max-md:space-y-10  max-md:gap-10 footer-item">
          <div className="space-y-5 colsocial">
            <img src="/images/home/bg-home.png" alt="" className="w-[200px] w-200pxdd brandlogpp" />
            <div className="flex gap-3 items-center justify-around my-top5">
              {/* <div> */}
              <a
                href="https://t.me/waxchainwax"
                target="_blank"
                rel="noopener noreferrer"
                className="AboutFooter__SocialLink-sc-6ec6c422-6 eiFKdQ"
              >
                <img className="w-10" src="https://www.svgrepo.com/show/349527/telegram.svg" alt="" />
              </a>
              <a
                href="https://x.com/waxchainwax"
                target="_blank"
                rel="noopener noreferrer"
                className="AboutFooter__SocialLink-sc-6ec6c422-6 eiFKdQ"
              >
                <img className="w-10" src="https://www.svgrepo.com/show/475689/twitter-color.svg" alt="" />
              </a>
              <a
                href="https://www.instagram.com/waxchainwax"
                target="_blank"
                rel="noopener noreferrer"
                className="AboutFooter__SocialLink-sc-6ec6c422-6 eiFKdQ"
              >
                <img className="w-10" src="https://www.svgrepo.com/show/452229/instagram-1.svg" alt="" />
              </a>
              {/* </div> */}
            </div>
            <p className="text-white my-top5" style={{ fontSize: "12px" }}>
              © 2024 WAXChain DEX Team
            </p>
          </div>
          <div className="space-y-5 colhkj">
            <h1 className="text-[18px] font-semibold text-white">App</h1>
            <ul className="space-y-3 font-normal text-[17px] text-white">
              <li className="my-top5">
                <a href="/swap">Swap</a>
              </li>
              <li className="my-top5">
                <a href="/liquidity">Liquidity</a>
              </li>
              <li className="my-top5">
                <a href="/farms">Farms</a>
              </li>
            </ul>
          </div>
          <div className="space-y-5 colhkj footerItempaddingLeft">
            <h1 className="text-[18px] font-semibold text-white">Community</h1>
            <ul className="space-y-3 font-normal text-[17px] text-white">
              <li className="my-top5">
                <a
                  href="https://x.com/waxchainwax"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="AboutFooter__SocialLink-sc-6ec6c422-6 eiFKdQ"
                >
                  Twitter
                </a>
              </li>
              <li className="my-top5">
                <a
                  href="https://t.me/waxchainwax"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="AboutFooter__SocialLink-sc-6ec6c422-6 eiFKdQ"
                >
                  Telegram
                </a>
              </li>
              <li className="my-top5">
                <a
                  href="https://www.instagram.com/waxchainwax"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="AboutFooter__SocialLink-sc-6ec6c422-6 eiFKdQ"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center  mx-20 max-md:mx-2 px-10 max-md:px-10 font-mono max-md:space-y-10  mt-5 rounded-2xl py-5 disclemartysjj">
          <div className="text-red-700 font-bold ">DISCLAIMER</div>
          <div
            className="flex items-center justify-center text-center text-sm text-gray-400 mt-2"
            style={{ lineHeight: "1.6" }}
          >
            Please review this legal disclaimer carefully. Seek professional advice for any doubt. The information
            provided is not exhaustive and doesn’t constitute part of a contractual agreement. While we strive for
            accuracy, the information, services, and timelines are subject to change without notice. This disclaimer
            doesn’t obligate anyone to enter a contract or binding commitment. By using this information, you
            acknowledge that it doesn’t contravene applicable laws, regulatory requirements, or regulations. Disclaimer:
            Cryptocurrency may be unregulated in your jurisdiction. The value of cryptocurrencies may fluctuate. Profits
            may be subject to capital gains or other taxes applicable in your jurisdiction.
          </div>
          <div className="text-gray-400 mt-2 text-center">© 2024 WaxChain. All Rights Reserved.</div>
        </div>
      </div>
    </section>
  );
};

export default MenuItem;
