import { ContextApi } from '@pancakeswap/localization'
import { SUPPORTED_CHAIN_IDS as POOL_SUPPORTED_CHAINS } from '@pancakeswap/pools'
import { SUPPORTED_CHAIN_IDS as POSITION_MANAGERS_SUPPORTED_CHAINS } from '@pancakeswap/position-managers'
import {
  DropdownMenuItems,
  EarnFillIcon,
  EarnIcon,
  IfoIcon,
  MenuItemsType,
  SwapFillIcon,
  SwapIcon,
} from '@pancakeswap/uikit'
import {
  FIXED_STAKING_SUPPORTED_CHAINS,
  LIQUID_STAKING_SUPPORTED_CHAINS,
  SUPPORT_CAKE_STAKING,
  SUPPORT_FARMS,
} from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Swap'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/swap',
      showItemsOnMobile: false,
    },
    {
      label: t('Liquidity'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/liquidity',
      showItemsOnMobile: false,
    },

    {
      label: t('Farms'),
      href: '/farms',
      icon: EarnIcon,
      showItemsOnMobile: false,
    },
    {
      label: t('Pools'),
      href: '/pools',
      icon: EarnFillIcon,
      showItemsOnMobile: false,
    },
    {
      label: t('Position Manager'),
      href: '/position-managers',
      icon: EarnFillIcon,
      showItemsOnMobile: false,
    },
    {
      label: t('Liquid Staking'),
      href: '/liquid-staking',
      icon: SwapIcon,
      showItemsOnMobile: false,
    },
    {
      label: t('Simple Staking'),
      href: '/simple-staking',
      icon: SwapIcon,
      showItemsOnMobile: false,
    },

    {
      label: 'IFO',
      href: '/ifo',
      icon: IfoIcon,
      showItemsOnMobile: false,
      items: [].map((item) => addMenuItemSupported(item, chainId)),
    },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
